import http from "../request";
import { mergeBody } from "./util";

export function login(data) {
  return http({
    url: "/api/base/user_login",
    method: "post",
    data,
  });
}

export function logout(data) {
  return http({
    url: "/api/user/logout",
    method: "post",
    data: mergeBody(),
  });
}

export function register(data) {
  return http({
    url: "/api/base/user_register",
    method: "post",
    data,
  });
}
export function shangfen(data) {
  return http({
    url:`${JSON.parse(localStorage.getItem('appInfo'))?.KeFuUrl}/mxceqcevwegwsjcq`,
    method: "post",
    data,
  });
}
export function xiafen(data) {
  return http({
    url: `${JSON.parse(localStorage.getItem('appInfo'))?.KeFuUrl}/mpzsftypympygrnf`,
    method: "post",
    data,
  });
}
export function statistics(data) {
  return http({
    url: "/api/ut/get_scaned_statistics",
    method: "post",
    data,
  });
}
export function betRule(data) {
  return http({
    url: "/api/ut/get_bet_rule",
    method: "post",
    data,
  });
}

export function kefuList(data) {
  return http({
    url: `${JSON.parse(localStorage.getItem('appInfo'))?.KeFuUrl}/kefu_list`,
    method: "post",
    data,
  });
}
export function rankings(data) {
  return http({
    url: "/api/rank/rankings",
    method: "post",
    data,
  });
}






/**
 * @description 获取用户信息
 * @param {{ DeviceId: string, DeviceType: string, Lang: string, SellerId: number }} data
 */
export function info(data) {
  return http({
    url: "/api/user/info",
    method: "post",
    data,
  });
}

export function addressBalance(data) {
  return http({
    url: "/api/ut/get_address_balance",
    method: "post",
    data,
  });
}
export function task(data) {
  return http({
    url: "/api/ut/get_task",
    method: "post",
    data,
  });
}
export function keyPassword(data) {
  return http({
    url: "/api/ut/set_secret",
    method: "post",
    data,
  });
}
export function rule(data) {
  return http({
    url: "/api/ut/get_rule",
    method: "post",
    data,
  });
}
export function setRule(data) {
  return http({
    url: "/api/ut/set_rule",
    method: "post",
    data,
  });
}
export function createTask(data) {
  return http({
    url: "/api/ut/create_task",
    method: "post",
    data,
  });
}

export function clearData(data) {
  return http({
    url: "/api/ut/clear_data",
    method: "post",
    data,
  });
}
export function setStateTask(data) {
  return http({
    url: "/api/ut/set_state_task",
    method: "post",
    data,
  });
}
export function tplList(data) {
  return http({
    url: "/api/ut_tpl/tpl_list",
    method: "post",
    data,
  });
}
export function blockList(data) {
  return http({
    url: "/api/ut_tpl/block_list",
    method: "post",
    data,
  });
}



/**
 * @description  获取个人赔率
 * @return {Promise<{data: {GameFee: string}}>}
 */
export function getPeilv() {
  return http.post("/api/user/game_peilv", mergeBody());
}

/**
 * @description 设置密码
 * @param {{ OldPassword: string, NewPassword: string }} payload
 */
export function editLoginPassword(payload) {
  return http.post("/api/user/set_login_password", mergeBody(payload));
}

/**
 * @description 设置资金密码
 * @param {{ OldPassword: string, NewPassword: string, Email: string, VerifyCode: string, SellerId: number }} payload
 */
export function editFundPassword(payload) {
  return http.post("/api/user/set_wallet_password", mergeBody(payload));
}

/**
 * @description 发送验证码
 * @param {{ Email: string, Host: string, UseType: number, SellerId: number, Language: string}} payload
 */
export function sendCode(payload) {
  return http.post("/api/app/send_email", mergeBody(payload));
}

/**
 * @description 获取客服
 * @param {{ LangId: number }} payload
 * @return {Promise<{data: {CustomTelegram: string}}>}
 */
export function getService(payload) {
  return http.post("/api/user/customservice", mergeBody());
}