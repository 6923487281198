import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import AxiosCanceler from "@/request/helper/axiosCancel";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home/index.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/register/index.vue"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/forgetPassword/index.vue"
      ),
  },
  {
    path: "/trade",
    name: "trade",
    meta: {
      keepAlive: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/trade/index.vue"),
  },
  {
    path: "/haxiGame",
    name: "haxiGame",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/haxiGame/index.vue"),
  },
  {
    path: "/chatRoom",
    name: "chatRoom",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/chatRoom/index.vue"),
  },
  {
    path: "/my",
    name: "my",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/my/index.vue"),
  },
  {
    path: "/charge",
    name: "charge",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/charge/index.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/withdraw/index.vue"),
  },
  {
    path: "/safeCenter",
    name: "safeCenter",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/safeCenter/index.vue"),
  },
  {
    path: "/editLoginPassword",
    name: "editLoginPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/editLoginPassword/index.vue"
      ),
  },
  // 资金密码
  {
    path: "/editFundsPassword",
    name: "editFundsPassword",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/editFundsPassword/index.vue"
      ),
  },
  // 提现地址
  {
    path: "/withdrawAddress",
    name: "withdrawAddress",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/withdrawAddress/index.vue"
      ),
  },
  // 添加钱包
  {
    path: "/addWallet",
    name: "addWallet",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/addWallet/index.vue"),
  },
  // 代理
  {
    path: "/invite",
    name: "invite",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/invite/index.vue"),
  },
  // 佣金记录
  {
    path: "/commissionRecord",
    name: "commissionRecord",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/commissionRecord/index.vue"
      ),
  },
  // 流水记录
  {
    path: "/liushuiRecord",
    name: "liushuiRecord",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/liushuiRecord/index.vue"),
  },
  {
    path: "/operationLog",
    name: "operationLog",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/operationLog/index.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/service/index.vue"),
  },
  // 投注记录
  {
    path: "/bettingRecords",
    name: "bettingRecords",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/betRecord/index.vue"),
  },
  // 投注记录
  {
    path: "/betRecord/detail",
    name: "bettingRecordsDetail",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/betRecord/detail.vue"),
  },
  // 教程
  {
    path: "/tutorial",
    name: "tutorial",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/tutorial/index.vue"),
  },
  // 开奖弹窗
  {
    path: '/resultVerify',
    name: 'resultVerify',
    component: () => import(/* webpackChunkName: "about" */ '@/views/game/resultVerify.vue'),
    meta: { keepAlive: false, isTabBar: false, requireAuth: false },
  },
  {
    path: '/Investment',
    name: 'Investment',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Investment/index.vue'),
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "about" */ '@/views/live/index.vue'),
  },
  {
    path: '/quantification',
    name: 'quantification',
    component: () => import(/* webpackChunkName: "about" */ '@/views/quantification/index.vue'),
  },
  {
    path: '/quantificationDetail',
    name: 'quantification',
    component: () => import(/* webpackChunkName: "about" */ '@/views/quantification/detail.vue'),
  },
  {
    path: '/liveDetail',
    name: 'liveDetail',
    component: () => import(/* webpackChunkName: "about" */ '@/views/live/detail.vue'),
  },
  {
    path: '/viewLive',
    name: 'viewLive',
    component: () => import(/* webpackChunkName: "about" */ '@/views/viewLive/index.vue'),
  },
  {
    path: '/yinshang',
    name: 'yinshang',
    component: () => import(/* webpackChunkName: "about" */ '@/views/yinshang/index.vue'),
  },
  {
    path: '/zhaoshang',
    name: 'zhaoshang',
    component: () => import(/* webpackChunkName: "about" */ '@/views/zhaoshang/index.vue'),
  },
  {
    path: '/98Commission',
    name: 'Commission',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/98Commission.vue'),
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/performance.vue'),
  },
  {
    path: '/98DirectlyUnder',
    name: 'DirectlyUnder',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/98DirectlyUnder.vue'),
  },
  {
    path: '/98Subordinate',
    name: 'Subordinate',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/98Subordinate.vue'),
  },
  {
    path: '/98Links',
    name: 'Links',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/98Links.vue'),
  },
  {
    path: '/commissionRecords',
    name: 'commissionRecords',
    component: () => import(/* webpackChunkName: "recommend" */ '@/views/98agent/commissionRecord.vue'),
  },
];

const router = new Router({
  routes,
});

const WHITE_LIST = [
  "/login",
  "/register",
  "/forgetPassword",
  "/trade",
  "/haxiGame",
  "/chatRoom",
  "/service"
];

const axiosCanceler = new AxiosCanceler();

router.beforeEach((to, _from, next) => {
  const token = store.state.userStore.token;
  if (WHITE_LIST.includes(to.path)) {
    if (["/login", "/register"].includes(to.path)) {
      store.commit("userStore/resetStore");
    }
    return next(true);
  }
  // http://localhost:8080/#/?AgentCode=84376632
  if(to.query.AgentCode){
    if (!token) return next({ replace: true, path: `/login?AgentCode=${to.query.AgentCode}` });

  }else {
    if (!token) return next({ replace: true, path: "/login" });

  }

  return next(true);
});

router.afterEach(() => {
  // 路由切换后，取消所有上一个页面正在请求的接口
  // axiosCanceler.removeAllPending();
  if (store.state.userStore.token) {
    Promise.resolve().then(() => {
      store.dispatch("userStore/getUserInfo");
      store.dispatch("userStore/getGamePeilv");
    });
  }
});

export default router;
